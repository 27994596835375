.input-box-container{
    label{
        font-size: 12px;
        line-height: 16.8px;
        font-weight: 500;
    }
    .input{
        outline: 0;
        padding: 12px 16px;
        border-radius: 4px;
        border: 1px solid #E7EBEC;
        background-color: #F7F9F9;
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 400;
    }
}