.blog_section blockquote {
    border-left: 10px solid #ff590c;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

.blog_section blockquote::before {
    color: #868584;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.blog_section blockquote p {
    display: inline;
}

.blog_section a {
  color: #ff590c;
}