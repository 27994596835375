.dialog-container {
  min-width: 500px;

  div[class*="modal-header"] {
    padding: 24px;

    div[class*="modal-title"] {
      font-family: Poppins;
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 900;
      color: #222d2f;
    }

    button[class*="btn-close"] {
      background-color: white;
      opacity: 1;
      color: #0c0c0c;
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 50%;
      box-shadow: 0 1px 8px #e7ebec;
    }
  }

  div[class*="modal-body"] {
    .body-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        margin-bottom: 0px;
      }

      div[class*="form-check"] {
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          color: #222d2f;
        }

        input[class*="form-check-input"] {
          width: 18px;
          height: 18px;

          &:focus {
            border-color: rgb(255 145 87 / 50%);
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(255 145 87 / 23%);
          }

          &:checked {
            background-color: #ff9157;
            border-color: #ff9157;
          }
        }
      }

      .row-label {
        font-weight: 500;
        font-size: 12px;
        color: #617275;
        margin-bottom: 0;
      }

      .upload-container {
        cursor: pointer;
        position: relative;
        border-radius: 80px;
        border: 1px dashed #b2cfd6;
        background-color: #e5faff;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 12px;
        justify-content: left;
        .label-title {
          margin: auto;
        }
        .label-filled {
          max-width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .label-title,
        .label-filled {
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
        }
      }
      .upload-file-desc {
        font-weight: 400;
        line-height: 16.8px;
        font-size: 12px;
        color: #617275;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  div[class*="modal-footer"] {
    justify-content: center;
    gap: 16px;

    .btn-cancel {
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      background-color: white;
      color: #ff9157;
      font-weight: 800;
      border: 1.2px solid #ff9157;
    }

    .btn-submit {
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      font-weight: 800;
      background: linear-gradient(90deg, #ff5700, #ff9057);
      color: #ffffff;

      &.disabled {
        background: linear-gradient(90deg, #ff5700, #ff9057);
        color: #ffffff;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
