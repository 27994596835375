
input[type=range].range-slider.range-slider--base {
	&::-webkit-slider-thumb {
		background: #FF5700;
	}
	&::-moz-range-thumb {
		background: #FF5700;
	}
	&::-ms-thumb {
		background: #FF5700;
	}
	&:not(.disabled) {
		&:focus {
			&::-webkit-slider-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
			&::-moz-range-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
			&::-ms-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
		}
		&:active {
			&::-webkit-slider-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
			&::-moz-range-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
			&::-ms-thumb {
				box-shadow: 0 0 0 0.2rem rgba(255, 87, 0, 0.75);
			}
		}
	}
}
