.revenue-manual-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .heading-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7ebec;

    .heading-left {
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .heading {
        font-size: 18px;
        line-height: 25.2px;
      }
    }

    .heading-right {
      margin-left: auto;

      button {
        padding: 18px 20px;
        background-color: #fff4ed;
        color: #ff5700;
        border: 1px solid #ff5700;
        border-radius: 100px;
        font-weight: 800;
        font-size: 14px;
        line-height: 23.8px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .content-filter {
      display: flex;
      align-items: center;

      .content-left {
        margin-right: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          padding: 12px 16px;
          font-size: 14px;
          line-height: 19.6px;
          font-weight: 500;
          border-radius: 20px;
          border: 1px solid #e7ebec;
          background-color: #ffffff;
          color: #617275;

          &.active {
            border: 1px solid #ffd5c0;
            background-color: #fff4ed;
            color: #ff5700;
          }
        }
      }

      .content-right {
        margin-left: auto;
      }
    }

    .content-table {
      table {
        border-radius: 20px;

        tr {
          th,
          td {
            padding: 16px 12px;
          }
        }

        thead {
          tr {
            background-color: #f6faff;

            th {
              font-size: 12px;
              line-height: 16.8px;
              font-weight: 700;
              color: #455356;
            }
          }
        }

        tbody {
          tr {
            background-color: #ffffff;

            td {
              font-size: 14px;
              line-height: 19.6px;
              font-weight: normal;
              color: #455356;

              &.title {
                color: #617275;
                font-weight: 700;
              }

              a {
                color: #0072da !important;
                font-weight: 500 !important;
                text-decoration: underline;
              }

              .status {
                padding: 2px 8px;
                border-radius: 30px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16.8px;

                &.approved {
                  color: #247d58;
                  background-color: rgba(227, 249, 225, 0.5);
                }
                &.processed {
                  color: #154230;
                  background-color: rgba(121, 255, 108, 0.5);
                }

                &.pending {
                  color: #9a6600;
                  background-color: rgba(255, 238, 219, 1);
                }

                &.rejected {
                  color: #de3730;
                  background-color: rgba(255, 237, 236, 1);
                }
              }

              button.btn-approve,
              button.btn-reject {
                padding: 8px 16px;
                border-radius: 100px;
                font-size: 11px;
                line-height: 15.4px;
                font-weight: 800;
              }

              button.btn-approve {
                border: 1px solid #ff9157;
                background: linear-gradient(90deg, #ff5700, #ff9057);
                color: #ffffff;
              }

              button.btn-reject {
                border: 1px solid #ff9157;
                background: white;
                color: #ff5700;
              }
            }
          }
        }
      }
    }
  }
}
