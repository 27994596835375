.breadcrumb{
    display: flex;
    align-items: center;
    gap: 8px;
    .item {
        cursor: pointer;
        font-size: 12px;
        line-height:16.8px;
        font-weight: 500;
        color: #617275;
        &.active{
            color:#FF5700;
        }
    }
    span{
        font-size: 11px;
        line-height:16px;
        font-weight: 500;
        color:#DBE1E3;
    }
}