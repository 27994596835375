.text-base {
    color: #FF5700;
}

.text-gray-50 {
    color: #F2F5F5;
}

.text-gray-100 {
    color: #E7EBEC;
}

.text-gray-200 {
    color: #DBE1E3;
}

.text-gray-300 {
    color: #B8C3C5;
}

.text-gray-400 {
    color: #A2AFB2;
}

.text-gray-500 {
    color: #819093;
}

.text-gray-600 {
    color: #617275;
}

.text-gray-700 {
    color: #566366;
}

.text-gray-800 {
    color: #455356;
}

.text-gray-900 {
    color: #222D2F;
}


.text-tan-50 {
    color: #FFF4ED;
}

.text-tan-100 {
    color: #FFE3D5;
}

.text-tan-200 {
    color: #FFD5C0;
}

.text-tan-300 {
    color: #FFAC80;
}

.text-tan-400 {
    color: #FF9157;
}

.text-tan-500 {
    color: #FF6718;
}

.text-tan-600 {
    color: #EE5100;
}

.text-tan-700 {
    color: #C54300;
}

.text-tan-800 {
    color: #9E3600;
}

.text-tan-900 {
    color: #541D00;
}

.bg-base {
    background-color: #FF5700;
}

.bg-gray-50 {
    background-color: #F2F5F5;
}

.bg-gray-100 {
    background-color: #E7EBEC;
}

.bg-gray-200 {
    background-color: #DBE1E3;
}

.bg-gray-300 {
    background-color: #B8C3C5;
}

.bg-gray-400 {
    background-color: #A2AFB2;
}

.bg-gray-500 {
    background-color: #819093;
}

.bg-gray-600 {
    background-color: #617275;
}

.bg-gray-700 {
    background-color: #566366;
}

.bg-gray-800 {
    background-color: #455356;
}

.bg-gray-900 {
    background-color: #222D2F;
}


.bg-tan-50 {
    background-color: #FFF4ED;
}

.bg-tan-100 {
    background-color: #FFE3D5;
}

.bg-tan-200 {
    background-color: #FFD5C0;
}

.bg-tan-300 {
    background-color: #FFAC80;
}

.bg-tan-400 {
    background-color: #FF9157;
}

.bg-tan-500 {
    background-color: #FF6718;
}

.bg-tan-600 {
    background-color: #EE5100;
}

.bg-tan-700 {
    background-color: #C54300;
}

.bg-tan-800 {
    background-color: #9E3600;
}

.bg-tan-900 {
    background-color: #541D00;
}