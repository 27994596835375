.food-page{
    .heading{
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing:-1px;
        font-weight: 800;
    }
    .heading-fy{
        border-radius: 80px;
        padding: 4px 12px 4px 12px;
        p{
            font-weight: 600;
            font-size: 12px;
            line-height: 16.8px;
            margin-bottom: 0px;
        }
    }
}