.menu-view {
    border: 2px solid #F0F2F5;
    border-radius: 16px;
    overflow: hidden;

    .menu-header {
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu-header-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 25.2px;
            color: #222D2F;
        }

        .status {
            font-size: 12px;
            font-weight: 600;
            line-height: 16.8px;
            color: #05603A;
            background-color: #D1FADF;
            padding: 4px 12px;
            border-radius: 80px;
        }

        .property-cap {
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #DBE1E3;
            color: #617275;
            font-size: 12px;
            line-height: 16.8px;
            font-weight: 400;
        }
    }

    .menu-content {
        .view-container {
            border-top: 1px solid #E7EBEC;

            .view-cell-wrapper {
                .cell {
                    position: relative;
                    padding: 12px;
                    line-height: 16.8px;
                    font-size: 12px;
                    font-weight: 500;

                    &.heading {
                        color: #566366;
                        font-weight: 700;
                        background-color: #F6FAFF;
                    }

                    &.cell-content{
                        color: #819093;
                        font-weight: 500;
                        &:nth-of-type(1){
                            color:#455356;
                        }
                        &:nth-of-type(2){
                            background-color:#F7F9F9;
                        }
                    }

                    border-left:1px solid #E7EBEC;

                    &:first-of-type {
                        border-left: 0px solid #E7EBEC;
                    }

                    .arrow-left,.arrow-right{
                        position: absolute;
                        cursor: pointer;
                    }
                    .arrow-left{
                        left:12px;
                    }
                    .arrow-right{
                        right:12px;
                    }
                }

                .first-cell {
                    width: 156px;
                }

                .equal-w-cell {
                    flex: 1 0 auto;
                }
            }

            &:last-of-type {
                border-bottom: 1px solid #E7EBEC;
            }

            &.heading-row-full {
                color: #455356;
                font-weight: 800;
                font-size: 14px;
                line-height: 23.8px;
                padding: 12px;

                &.breakfast {
                    background-color: #CFFBFC;
                }

                &.lunch {
                    background-color: #E5F3FF;
                }

                &.snacks {
                    background-color: #FFEEDB;
                }

                &.dinner {
                    background-color: #FFECF0;
                }
            }
        }
    }
}