.poll-container{
    display: flex;
    flex-direction: column;
    gap:0px;
    border: 1px solid #E7EBEC;
    border-radius: 20px;
    background-color: white;
    .poll-header{
        padding: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #E7EBEC;
        gap:20px;
        .header-child{
            flex: 0 0 auto;
            width: 33.33333%;
            &:nth-of-type(2){
                width: 60%; 
            }
        }
    }
    .poll-menu{
        padding: 20px;
        border-bottom: 1px solid #E7EBEC;
        display: flex;
        flex-direction: column;
        .poll-menu-first-col{
            border-right: 1px solid #E7EBEC;
        }
        .add-course{
            cursor: pointer;
            font-size: 14px;
            font-weight: 800;
            line-height: 23.8px;
        }
    }
}