.createmenu-header {
    .menu-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 25.2px;
        color: #222D2F !important;
        margin-bottom: 0px;
    }

    button.draft {
        padding: 16px 24px;
        border-radius: 50px;
        border: 1px solid #FFE3D5 !important;
        background: white;
        color: #FF5700 !important;
        font-size: 14px;
        font-weight: 800;
        line-height: 23.8px;
        &.disabled{
            cursor: not-allowed;
            color: #8C8C8C;
            opacity: 0.5;
        }
    }

    button.publish {
        padding: 16px 24px;
        border-radius: 50px;
        background: linear-gradient(90deg, #FF5700, #FF9057);
        color: #ffffff !important;
        font-size: 14px;
        font-weight: 800;
        line-height: 23.8px;
        &.disabled{
            cursor: not-allowed;
            background: #FAE1D4;
            color: #8C8C8C;
            border:0;
        }
    }
}

button.next-poll {
    padding: 12px 16px;
    display: inline-flex;
    gap: 8px;
    border-radius: 44px;
    background-color: white;
    border: 1px solid #FFD5C0 !important;
    color: #FF5700 !important;
    font-size: 14px;
    line-height: 23.8px;
    font-weight: 800;
}

.createmenu-container {
    .add-view {
        display: flex;
        justify-content: center;

        .description {
            font-weight: 400;
            font-size: 18px;
            line-height: 25.2px;
            color: #617275;
        }

        .create-menu {
            display: flex;
            justify-content: center;

            .create-menu-cta {
                min-width: 275px;
                padding: 16px 32px;
                border-radius: 44px;
                background: linear-gradient(90deg, #FF5700, #FF9057);
                box-shadow: 0 12px 24px -9px rgba(255, 87, 0, 0.48);
                font-weight: 800;
                font-size: 12px;
                line-height: 20.4px;
                color: white;
            }
        }
    }

    margin-bottom: 3rem;
}