.filter-date {
    .view-container {
        border: 1px solid #FFE3D5;
        padding: 16px 20px 16px 20px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        .date-range {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 800;
            font-size: 14px;
            line-height: 23.8px;
            color: #222D2F;
        }
    }

    div[class*="react-datepicker-wrapper"] {
        display: block;
        height: 0px;

        div[class*="react-datepicker__input-container"] {
            display: none;
        }
    }

    div[class*="react-datepicker__header"] {
        background-color: #FFF4ED;
        border: none;
    }

    div[class*="react-datepicker__day-name"]{
        color: #000000;
        font-weight: 700;
    }

    div[class*="react-datepicker__week"] {
        div[class*="react-datepicker__day"] {
            border-radius: 50%;

            &[class*="react-datepicker__day--disabled"]:hover{
                background: transparent !important;
                color: #0000004f !important;
            }

            &:hover,
            &[class*="react-datepicker__day--in-selecting-range"] {
                color: #fff;
                background: #0F7691;
            }

            &[aria-selected="true"],
            &[class*="react-datepicker__day--keyboard-selected"] {
                color: #fff;
                background: var(--gradient-orange, linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%));
            }
        }
    }

    div[class*="react-datepicker__month"] {
        background-color: #FFF4ED;
    }

    button[class*="react-datepicker__navigation"] {
        span {
            margin-top: 10px;
        }
    }

    button.apply{
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: white;
        padding: 10px 20px;
        border-radius: 100px;
        background: linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%);
    }
    button.cancel{
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        padding: 10px 20px;
        border-radius: 100px;
        color: #FF5700;
        border:1px solid #FFE3D5;
        border-radius: 100px;
    }
}