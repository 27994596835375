.gap-4{
    gap:4px;
}
.gap-8{
    gap:8px;
}
.gap-10{
    gap:10px;
}
.gap-12{
    gap:12px;
}
.gap-16{
    gap:16px;
}
.gap-20{
    gap:20px;
}
.gap-24{
    gap:24px;
}
.gap-y-10{
   row-gap: 10px;
}