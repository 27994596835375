.rotate-90deg{
    transform: rotate(90deg);
}
.rotate-180deg{
    transform: rotate(180deg);
}
.rotate-270deg{
    transform: rotate(270deg);
}
.rotate-360deg{
    transform: rotate(360deg);
}