$font-manrope: 'Manrope", "sans-serif';

.font-manrope {
  font-family: 'Manrope', sans-serif;
}

.font-zkga {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Manrope', sans-serif !important;
  font-weight: 400 !important;
  background: #005E77 !important;
  padding: 8px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// SIDEBAR CSS
#sidebar.left-bar {
  min-width: 80px;
  max-width: 80px;
  background: #0F7691;
  border-radius: 20px;
}

#sidebar.left-bar ul li a {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  text-align: center;
  color: #fff;
  padding: 12px 4px;
  border: none;
  margin-bottom: 12px;
  text-decoration: none;
}

.left-bar .dropdown-toggle::after {
  opacity: 0;
}

.main_logo {
  display: block;
  margin-bottom: 40px;
  text-align: center;
}

.left-menu-box h1 {
  margin: 0 !important;
}

.left-bar #sidebarCollapse {
  opacity: 0;
}

.right-bar {
  border-radius: 30px;
  background: #fff !important;
  padding: 20px !important;
  margin-left: 8px;
}

#sidebar.left-bar ul li a span {
  display: block;
  margin-bottom: 4px;
  text-align: center;
}

#sidebar.left-bar ul.components {
  padding: 0 5px;
}

#sidebar.left-bar ul li a:hover,
#sidebar.left-bar ul li a[aria-expanded="true"],
#sidebar.left-bar ul ul li a.active {
  background-color: #3EA2BD;
  border-radius: 12px;
}

#sidebar.left-bar ul li {
  position: relative;
}

#sidebar.left-bar ul ul {
  border-radius: 20px;
  position: absolute;
  left: calc(100% + 15px);
  background: #0F7691;
  top: -10px;
  padding: 12px;
  min-width: 230px;
  z-index: 99;
}

#sidebar.left-bar ul ul li a {
  margin-bottom: 5px;
  text-align: left;
  padding: 12px;
  display: flex;
  align-items: center;
}

#sidebar.left-bar ul ul::after {
  content: '';
  background-image: url(../public/images/Polygon.svg);
  background-repeat: no-repeat;
  width: 8px;
  height: 14px;
  display: block;
  position: absolute;
  top: 40px;
  left: -7px;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
  border: none;
}

.wrapper {
  width: 100%;
  min-height: 97vh;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #866ec7;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

#sidebar .h6 {
  color: #fff;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar.active .custom-menu {
  margin-right: -50px;
}

#sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
}

#sidebar h1 .logo {
  color: #fff;
}

#sidebar ul.components {
  padding: 0;
}

#sidebar ul li {
  font-size: 16px;
}

#sidebar ul li>ul {
  margin-left: 10px;
}

#sidebar ul li>ul li {
  font-size: 14px;
}

#sidebar ul li a {
  position: relative;
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a:hover {
  color: #fff;
}

#sidebar ul li.active>a {
  background: transparent;
  color: #fff;
}

.modal_input_main select.form-control {
  background-image: url(../public/images/Arrow_down.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 95% !important;
}

.p-16-32-button {
  padding: 16px 32px !important;
}

@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -250px;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #sidebar .custom-menu {
    margin-right: -50px !important;
    top: 10px !important;
  }
}

#sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar .custom-menu {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

#sidebar .custom-menu .btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

#sidebar .custom-menu .btn.btn-primary {
  background: #6749b9;
  border-color: #6749b9;
}

#sidebar .custom-menu .btn.btn-primary:hover,
#sidebar .custom-menu .btn.btn-primary:focus {
  background: #6749b9 !important;
  border-color: #6749b9 !important;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.right-side-header h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25.2px;
  letter-spacing: -0.36px;
  color: #222D2F;
  margin: 0 10px 0 20px;
}

.breadcumb-header h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25.2px;
  letter-spacing: -0.36px;
  color: #222D2F;
}

.tit_point {
  padding: 4px 12px;
  background-color: #E7EBEC;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  color: #617275;
  border-radius: 80px;
}

.breadcum ul {
  margin: 0;
  padding: 0;
}

.breadcum ul li {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  color: #FF5700;
  list-style-type: none;
  display: inline-block;
  padding: 4px 0px 4px 4px;
}

.breadcum ul li a {
  font-weight: 500;
  color: #617275;
  text-decoration: none;
}

.breadcum ul li span {
  margin: 0 8px;
  display: inline-block;
  color: #DBE1E3;
}

.right-side-header,
.breadcumb-header {
  border-bottom: 1px solid #E7EBEC;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.basic-button {
  font-size: 12px;
  font-weight: 800;
  line-height: 23.8px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  padding: 12px 16px;
  text-align: center;
  background: linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%);
  border-radius: 100px;
  border: none;
  color: #fff;
}

.basic-button-small {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 10px;
  text-align: center;
  background: linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%);
  border-radius: 10px;
  border: none;
  color: #fff;
}

.basic-button img {
  position: relative;
  top: -1px;
}

.status-box a {
  display: inline-block;
  border-radius: 100px;
  border: 1px solid #E7EBEC;
  padding: 12px;
  text-align: center;
  color: #617275;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  margin-right: 12px;
  text-decoration: none;
}

.status-box a:last-child {
  margin-right: 0;
}

.status-box a:hover,
.status-box a.active {
  background: #FFF4ED;
  border: 1px solid #FFD5C0;
  color: #FF5700;
  text-decoration: none;
}

.search_box input {
  border-radius: 10px;
  border: 1px solid #DBE1E3;
  padding: 12px 32px 12px 12px !important;
  background-color: #F7F9F9;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  padding: 12px;
  width: 220px;
  background-image: url(../public/images/Search.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}

.search_box input:focus {
  outline: none;
}

.pb-32 {
  padding-bottom: 32px;
}

div#content.right-bar {
  // height: calc(100vh - 16px);
  min-height: auto;
  overflow-y: auto;
}

// TABLE CSS
.page-table-box table {
  width: 100%;
  z-index: 0;
}

.page-table-box table th {
  background-color: #F6FAFF;
  padding: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  color: #455356;
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0px -24px 24px -16px rgba(231, 235, 236, 0.5) inset
}

.page-table-box table td {
  font-size: 14px;
  padding: 12px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  color: #617275;
  border-bottom: 1px solid #E7EBEC;
}


.page-table-box table tr:hover td {
  box-shadow: 0px -24px 24px -16px rgba(231, 235, 236, 0.5) inset
}

.page-table-box table td:first-child {
  font-weight: 700;
}

.success_status_badge {
  padding: 2px 8px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  background-color: #E3F9E1;
  border-radius: 30px;
  color: #247D58;
  text-decoration: none;
}

td a.bg_green_txt {
  padding: 2px 8px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  background-color: #E3F9E1;
  border-radius: 30px;
  color: #247D58;
  text-decoration: none;
}

td a.bg_warning_txt {
  padding: 2px 8px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  background-color: #FFEEDB;
  border-radius: 30px;
  color: #9A6600;
  text-decoration: none;
}

td a.bg_red_txt {
  padding: 2px 8px;
  text-align: center;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  background-color: rgba(222, 17, 53, 0.05);
  border-radius: 30px;
  color: #DE1135;
  text-decoration: none;
}

th.w130 {
  width: 130px;
}

th.w120 {
  width: 120px;
}

th.w100 {
  width: 100px;
}

.page-table-box {
  height: calc(100vh - 280px);
  overflow-y: auto;
}

.active {
  background-color: #3EA2BD;
  border-radius: 12px;
}

@media screen and (min-width:1024px) and (max-width:1279px) {
  .page-table-box {
    height: calc(100vh - 310px);
  }
}

@media screen and (min-width:768px) and (max-width:979px) {
  .page-table-box {
    height: calc(100vh - 350px);
  }

  .right-bar {
    width: calc(100% - 90px) !important;
  }

  #sidebar.left-bar {
    margin-left: 0;
  }
}


/**/

.mobile-layout {
  display: none;
}

.main-content-mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .left-bar {
    display: none;
  }

  .right-bar .main-content-web {
    display: none;
  }

  .mobile-layout {
    display: block;
  }

  .main-content-mobile {
    display: block;
  }

  body {
    background: #F8F8F8 !important;
    padding: 0px !important;
  }

  .right-bar {
    border-radius: 0px;
    background: none !important;
    padding: 0px !important;
    margin-left: 0px;
  }

  .table_main_tit h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #222D2F;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .table_main_tit h5 b {
    font-weight: 600;
  }

  .status-box a {
    background: #fff;
    border: 1px solid #E7EBEC;
    color: #FF5700;
    padding: 8px 16px;
  }

  .pd-20-16 {
    padding: 20px 16px;
  }

  .list_main_mob .img_round_icon_box {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .list_main_mob .img_round_icon_box img {
    width: 40px;
    height: 40px;
  }

  .mob_list_data h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.16px;
    color: #222D2F;
    margin-bottom: 0;
  }

  .mob_list_data h4 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #617275;
    margin-bottom: 0;
  }

  .bg_green_txt {
    padding: 2px 8px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    background-color: #E3F9E1;
    border-radius: 30px;
    color: #247D58;
    text-decoration: none;
  }

  .bg_warning_txt {
    padding: 2px 8px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    background-color: #FFEEDB;
    border-radius: 30px;
    color: #9A6600;
    text-decoration: none;
  }

  .bg_danger_txt {
    padding: 2px 8px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    background: rgba(222, 17, 53, 0.05);
    border-radius: 30px;
    color: #DE1135;
    text-decoration: none;
  }

  .list_brd_btm_mob {
    border-bottom: 1px solid #E7EBEC;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .mob-list-sub-data h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #A2AFB2;
    margin-bottom: 2px;
  }

  .mob-list-sub-data h3 {
    font-size: 13px;
    color: #455356;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    margin-bottom: 0;
  }

  .list_main_mob {
    margin-bottom: 8px;
  }

  div#content.right-bar {
    height: auto;
  }

  .list_scroll_mob {
    height: calc(100vh - 185px);
    overflow-y: auto;
  }

  .brd_box_card {
    height: auto !important;
  }

  .mob-bottom-menu {
    border-radius: 13px;
    background: #0F7691;
    position: absolute;
    width: calc(100% - 32px);
    bottom: 10px;
    padding: 4px 4px 0;
    margin: 0 16px;
  }

  .mob-bottom-menu-lead {
    border-radius: 13px;
    background: #0F7691;
    position: absolute;
    width: calc(100% - 32px);
    bottom: 74px;
    padding: 4px 4px 0;
    margin: 0 16px;
    text-align: center;
    font-weight: bold;
    color: white;
    padding-bottom: 3px;
  }

  .mob-bottom-menu a,
  .mob-bottom-menu button  {
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #fff;
    padding: 8px;
    text-align: center;
    border-radius: 10px;
  }

  .mob-bottom-menu a:hover,
  .mob-bottom-menu a.active
  .mob-bottom-menu button:hover,
  .mob-bottom-menu button.active {
    background: #3EA2BD;
    border-radius: 10px;
  }

  .mob-bottom-menu a i,
  .mob-bottom-menu button i {
    display: block;
    text-align: center;
    font-style: normal;
    margin-bottom: 4px;
  }

  .mob-bottom-menu a span,
  .mob-bottom-menu button span {
    display: block;
  }

  .mob_details_header {
    padding: 16px;
    background-image: url(../public/images/details_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .mob_details_header.table_main_tit h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    color: #fff;
    margin-bottom: 0;
    letter-spacing: -0.16px;
  }

  .details_right_top_part {
    border-radius: 100px;
    background: #F7F9F9;
    padding: 4px 8px;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #455356;
  }

  .mob_details_middle {
    padding: 16px;
  }

  .mob_details_middle .main_tab .nav-tabs .nav-link:focus,
  .mob_details_middle .main_tab .nav-tabs .nav-link:hover,
  .mob_details_middle .main_tab .nav-tabs .nav-link:hover:focus,
  .mob_details_middle .main_tab .nav-tabs .nav-link.active {
    background: none;
  }

  .mob_ledger_table .page-table-box {
    height: calc(100vh - 350px) !important;
    overflow-y: auto;
    margin-bottom: 21px;
  }

  .orange_brd_button {
    width: 100%;
  }

  .commen_table .page-table-box table th {
    flex: 1;
  }

  .commen_table .page-table-box table td {
    text-align: center;
  }

  .commen_table .page-table-box table tr td:first-child {
    filter: drop-shadow(4px 12px 24px rgba(12, 12, 12, 0.02));
  }

  .list_scroll_mob {
    padding: 16px;
  }

  .list-header-mob {
    padding: 16px 16px 0;
  }

  .mob_details_middle .main_tab ul {
    justify-content: center;
  }

  .mob_details_middle .main_tab .nav-tabs .nav-link {
    margin-right: 15px;
  }

  .mob_details_middle .main_tab .nav-tabs .nav-item:last-child .nav-link {
    margin-right: 0px;
  }

  .mob_details_tab_box {
    height: calc(100vh - 275px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mob_details_tab_box .info_width {
    min-width: auto;
    margin-right: 0;
  }

  .mob_details_tab_box .sub_exit_data_txt {
    margin-right: 0px;
    min-width: auto;
  }

  .list_top_brd {
    border-top: 1px solid #E7EBEC;
    padding-top: 16px;
    margin-top: 16px !important;
  }

  .sub_exit_box .sub_exit_data_txt span {
    margin-bottom: 16px !important;
  }

  .bg_white {
    background: #fff;
  }

  .agreement_details_data .img_round_icon_box img {
    width: 48px;
    height: 48px;

  }

  .agreement_details_data .img_round_icon_box {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    margin-right: 12px;
  }

  .agreement_details_data h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #819093;
    margin-bottom: 8px;
  }

  .agreement_details_data span {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #455356;
    margin-bottom: 24px;
    display: block;
  }

  .agreement_details_data .col-6:last-child span {
    margin-bottom: 0;
  }

  .agreement_details_data {
    margin-bottom: 16px;
  }

  .agreement_details_data:last-child {
    margin-bottom: 0;
  }

  .w460 {
    width: 100% !important;
  }

  .checkin_tab_mob .small_box_center {
    padding: 12px 20px;
  }

  .checkin_tab_mob .disc_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #617275;
  }

  .checkin_tab_mob .disc_text b {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #222D2F;
  }

  .last_update_txt {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #A2AFB2;
    margin-top: 12px;
    display: block;
  }

  .checkin_tab_mob .check_text .info_width {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .checkin_tab_mob .box_contain {
    padding-bottom: 0;
  }

  .check_tab_list+.basic-button {
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }

  .check_popup_mobile .modal_btn_footer {
    display: block;
  }

  .check_popup_mobile .modal_btn_footer .basic-button {
    width: 100%;
    margin-bottom: 16px;
  }

  .exit_mob_tab .sub_exit_data_txt h3 {
    margin-bottom: 0;
  }

  .exit_mob_tab .sub_exit_box .sub_exit_data_txt span {
    margin-bottom: 0 !important;
  }

  .exit_mob_tab .sub_exit_data_txt {
    margin-bottom: 16px;
  }

  .exit_mob_tab .sub_exit_data_txt:last-child {
    margin-bottom: 0px;
  }

  .p-12-32-button {
    padding: 12px 32px !important;
    text-transform: uppercase;
  }

  .mob_exit_confirm.confirm_noc_modal.modal .modal-content {
    border-radius: 0 !important;
  }

  .mob_exit_confirm.confirm_noc_modal .modal-body {
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .mob_exit_confirm.confirm_noc_modal .modal-dialog.modal-md.modal-dialog-centered {
    margin: 0px;
  }

  .mob_exit_confirm.confirm_noc_modal .modal-content {
    border: none !important;
  }

  .px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .exit_modal_header .table_main_tit {
    border-bottom: 1px solid #E7EBEC;
    margin-bottom: 17px;
  }

  .mob_exit_main_tit h2 {
    font-size: 18px;
    font-weight: 800;
    line-height: 25.2px;
    letter-spacing: -0.36px;
    color: #222D2F;
  }

  .mob_exit_main_tit p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #455356;
    margin-bottom: 20px;
  }

  .mob_exit_list_box h4 {
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #617275;
    margin-bottom: 8px;
  }

  .mob_exit_list_box h4 span {
    font-weight: 700;
    margin-left: 14px;
    margin-top: -5px;
  }

  .mob_exit_list_box h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #566366;
    margin-bottom: 8px;
  }

  .list_part_exit_mob {
    padding: 16px 20px;
    border-radius: 16px;
    border: 1px dashed #DBE1E3;
  }

  .mob_exit_list_img img {
    border-radius: 12px;
  }

  .mob_exit_list_box {
    margin-bottom: 20px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .exit_mob_tab_confirm_scroll {
    overflow-y: auto;
    height: calc(100vh - 324px);
  }

  .mob_slider_pop .modal-body {
    padding: 24px 20px !important;
  }

  .mob_slider_pop .close_modal {
    top: 23px;
  }

  .preview_middle_box h2 {
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #617275;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .slider_big_photo img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
  }

  .mob_slider_pop .modal-content {
    border-radius: 20px !important;
  }

  .slider_big_photo {
    margin-bottom: 20px;
  }

  .slider_small_photo img {
    border-radius: 12px;
    object-fit: cover;
    width: 72px;
    height: 72px;

  }

  .slider_small_photo.active img {
    outline: 2px solid #FFAC80;
    outline-offset: 4px;
  }

  .slider_small_main {
    display: inline-flex;
    overflow-x: auto;
    width: auto;
    max-width: 100%;
    padding: 10px;
  }

  .slider_small_photo {
    margin-right: 14px;
    min-width: 72px;
  }

  .photo_slider_main {
    text-align: center;
  }

  .slider_small_main_wapper {
    margin: -10px;
  }

  .abscoding_modal .modal_btn_footer {
    display: block;
    margin-top: 32px;
  }

  .abscoding_modal .modal_btn_footer button {
    font-weight: 800;
    font-size: 14px;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;

  }

  .head_part_date_modal h2 {
    margin-bottom: 15px !important;
  }

  .abscoding_modal .close_modal {
    top: 23px;
  }

  .abscoding_modal .modal_input_main .react-datepicker,
  .abscoding_modal .modal_input_main .react-datepicker__header {
    background: #fff;
  }

  .date_first {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #FF5700;
    margin-bottom: 36px;
    text-align: left;
    font-family: Manrope !important;
    margin-left: 10px;
  }

  .date_second h3 {
    margin-left: 10px;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    font-family: Manrope !important;
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid #E7EBEC;
    padding-bottom: 10px;
  }

  .left_right_box {
    margin: 5px 10px 0 10px;
  }

  .date_icon_prev_next button {
    background: none;
    border: none;
    box-shadow: none;
  }

  .abscoding_modal .react-datepicker__children-container {
    width: 100%;
  }

  .specific_button button.bg_color {
    width: 54px;
    box-shadow: 0px 12px 24px -9px rgba(255, 87, 0, 0.48);
    background: #FFF4ED
  }

  .specific_button button+button {
    width: calc(100% - 100px);
    box-shadow: 0px 12px 24px -9px rgba(255, 87, 0, 0.48);
  }

  .specific_button {
    margin-top: 15px;
  }

  .abscoding_modal .modal_input_main .react-datepicker__input-container input {
    background-image: url(../public/images/calendar-plus.svg);
    background-repeat: no-repeat;
    background-position: 95%;
  }

  .abscoding_modal .modal_input_main .react-datepicker__input-container input+button.react-datepicker__close-icon {
    display: none;
  }

  .w48 {
    width: 48%;
  }
}

.cursor-pointor {
  cursor: pointer;
}

.user_name {
  margin-left: 6px;
  color: #617275 !important;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  position: relative;
  top: 2px;
}

.page-table-box table td span span {
  color: #fff;
  font-size: 11px;
  line-height: 15.4px;
  letter-spacing: 0.88px;
}

.fancybox__container .fancybox__carousel.is-ltr.is-horizontal {
  width: 55%;
  margin: auto;
}

.fancybox__container .f-button.is-prev {
  background-image: url(../public/images/next_arrow.svg);
  background-repeat: no-repeat;
  display: block;
  width: 44px;
  height: 44px;
  box-shadow: 8px 16px 20px -12px rgba(12, 12, 12, 0.22);
}

.fancybox__nav .f-button.is-prev:before {
  position: absolute;
  content: "";
  top: 2px;
  right: -20px;
  left: 0px;
  bottom: -30px;
  z-index: 1;
  box-shadow: 8px 16px 20px -12px rgba(12, 12, 12, 0.22);
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.fancybox__container .f-button.is-next {
  background-image: url(../public/images/prev_arrow.svg);
  background-repeat: no-repeat;
  display: block;
  width: 44px;
  height: 44px;
  box-shadow: 8px 16px 20px -12px rgba(12, 12, 12, 0.22);
}

.fancybox__nav .f-button.is-next:before {
  position: absolute;
  content: "";
  top: 2px;
  right: 12px;
  left: -8px;
  bottom: -50px;
  z-index: 1;
  box-shadow: 8px 16px 20px -12px rgba(12, 12, 12, 0.22);
  width: 44px;
  height: 44px;
  border-radius: 100%;
  z-index: -1;
}

.fancybox__container .fancybox__nav .f-button svg {
  display: none;
}

.form_control_box .form-check-input {
  width: 24px;
  height: 24px !important;
  border-width: 7px !important;
}

.form_control_box .form-check-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #222D2F;
  position: relative;
  top: 5px;
  left: 3px;
}

.form_control_box .form-check-inline {
  margin: 4px 15px 0 0 !important;
  padding: 0;
}

.custom-single-select .form-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  color: #617275;
  margin-bottom: 4px;
  font-style: normal;
}

.notice_modal .modal_input_main .react-datepicker {
  background: #fff;
  border: 1px solid #E7EBEC;
}

.notice_modal .modal_input_main .react-datepicker__header {
  background: #fff;
}

.notice_modal .react-datepicker__day,
.notice_modal .react-datepicker__day-name {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.notice_modal .react-datepicker__day--in-selecting-range:not(.notice_modal .react-datepicker__day--in-range,
  .notice_modal .react-datepicker__month-text--in-range,
  .notice_modal .react-datepicker__quarter-text--in-range,
  .notice_modal .react-datepicker__year-text--in-range) {
  background-color: #FFF4ED;
}

.notice_modal .modal_input_main .react-datepicker__day--outside-month,
.notice_modal .modal_input_main .react-datepicker__day--outside-month:hover {
  background: white !important;
}

.notice_modal .modal_input_main .react-datepicker__day--selected,
.notice_modal .modal_input_main .react-datepicker__day--selected:hover {
  border-radius: 50%;
  color: #FF5700;
  background: white !important;
  border: 1px solid #FF5700;
}

.custom-single-select .react-dropdown-select {
  border: none;
  border-radius: 8px;
  border: 1px solid #E7EBEC;
  background: #F2F5F5;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #222D2F;
  padding: 12px 16px;
  width: 100%;
  height: auto !important;
}

.custom-single-select .react-dropdown-select-dropdown {
  border-radius: 8px;
  background: #FFF;
  padding: 12px;
  box-shadow: 0px 10px 22px -5px rgba(0, 0, 0, 0.5);
  border: none;
  width: 100%;
}

.custom-single-select .react-dropdown-select-item {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #617275;
  padding: 8px;
  border-bottom: 1px solid #E7EBEC;

}

.custom-single-select .react-dropdown-select-item-selected,
.custom-single-select .react-dropdown-select-item:hover {
  background: #FFEEDB !important;
  color: #000 !important;
  border: none !important;
}

.modal_input_main .react-datepicker__input-container input {
  background-image: url(../public/images/calendar-plus-01.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 95% !important;
}

.year-text {
  color: #617275;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.18px;
}

.status_txt_top {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.success_status {
  color: #36B37E;
}

.status_txt_top img {
  position: relative;
  margin-right: 6px;
  top: -1px
}

.copy-custom-toast {
  display: flex;
  min-height: 60px;
  padding: 12px 8px 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #222D2F;
  width: 400px;
}

.copy-custom-toast .message {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: -0.28px;
}

.page-table-box {
  scrollbar-color: #007 #bada55;
}

// SERVER DATATABLE CSS
.sr_data_table .rdt_TableHead .rdt_TableHeadRow {
  border-radius: 10px;
  background: #F6FAFF;
  min-height: auto;
  height: 48px !important;
}

.sr_data_table .rdt_TableHead .rdt_TableHeadRow .brFloq {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: -0.12px;
  padding: 12px;
}

.sr_data_table .rdt_TableHead .rdt_TableHeadRow .cygFkb {
  padding: 0;
}

.sr_data_table .rdt_TableBody .rdt_TableRow {
  background: #fff !important;
  color: rgb(26, 4, 75) !important;
  border-bottom: 1px solid #E7EBEC;
  cursor: pointer;
}

.sr_data_table .rdt_TableBody .rdt_TableRow:hover {
  filter: drop-shadow(4px 6px 9px rgba(12, 12, 12, 0.08));
}

.sr_data_table .rdt_TableBody .rdt_TableRow .hgByPU div:first-child {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  color: #617275;
  padding: 12px;
}

.sr_data_table .rdt_TableBody .rdt_TableRow .dKzMcW {
  padding: 0;
}

.sr_data_table .rdt_TableBody .rdt_TableRow .hbgCLW {
  min-height: auto;
}

.sr_data_table .rdt_Pagination {
  background: #fff !important;
  color: #000 !important;
}

.sb-avatar__text span span {
  font-size: 11px;
  font-weight: 800;
  line-height: 15.4px;
  letter-spacing: 0.88px;
  color: #fff;
}

.sr_data_table .jIiZGR {
  max-height: calc(100vh - 307px);
}

.address_details {
  text-align: end;
  width: 0px;
}

.date-updated-text {
  color: #A2AFB2;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.28px;
}

.asset-image {
  width: 52px;
  height: 40px;
}

.header-text {
  color: #222D2F;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  line-height: 22.4px;
  letter-spacing: -0.32px;
}

.resp-asset-image {
  height: 50px;
  width: 50px;
}

.custom-single-select .react-dropdown-select-content .react-dropdown-select-input {
  font-size: 16px;
}

.add_bill_type_btn {
  color: #FF5700;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 23.8px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  display: inline-flex;
  // padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0px;
  background: #FFF;
}

.custom-file-input {
  background: #FFF;
  display: none;
}

.float-right {
  float: right;
}

.hr-line {
  color: #a7a4a0;
}


button.btn-approve,
button.btn-reject {
  padding: 8px 16px;
  border-radius: 100px;
  font-size: 11px;
  line-height: 15.4px;
  font-weight: 800;
}

button.btn-approve {
  border: 1px solid #ff9157;
  background: linear-gradient(90deg, #ff5700, #ff9057);
  color: #ffffff;
}

button.btn-reject {
  border: 1px solid #ff9157;
  background: white;
  color: #ff5700;
}


@media only screen and (max-width: 767px) {

  .sr_data_table .jIiZGR {
    max-height: calc(100vh - 360px);
  }
}

.notification-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notification-popup {
  position: relative;
  width: 400px;
  background-color: #FFF;
  border: 1px solid #E7EBEC;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.notification-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #E7EBEC;
}

.notification-popup-title {
  font-size: 18px;
  font-weight: 700;
  color: #222D2F;
}

.notification-popup-close {
  cursor: pointer;
  font-size: 16px;
  color: #DE1135;
}

.notification-popup-body {
  margin-top: 10px;
}

.notification-popup-item {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #E7EBEC;
}

.notification-popup-item:last-child {
  border-bottom: none;
}

.notification-popup-item-title {
  font-size: 14px;
  font-weight: 600;
  color: #455356;
  display: flex;
  align-items: center;
}

.notification-popup-item-action {
  background-color: #FF5700;
  color: #FFF;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  min-width: 150px;
}

.notification-popup-item-action:hover {
  background-color: #fc6c24;
}

@media (max-width: 768px) {
  .mob-notification-popup {
    width: 90%;
    padding: 15px;
  }

  .mob-notification-popup-title {
    font-size: 16px;
  }

  .mob-notification-popup-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .mob-notification-popup-item-title {
    position: relative;
  }

  .mob-notification-popup-item-action {
    align-self: stretch;
    text-align: center;
    min-width: 150px;
  }
}

.mob_col_sch_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  backdrop-filter: blur(10px);
  /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mob_col_sch_popup .popup_inner {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.mob_col_sch_popup .popup_inner .popup_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mob_col_sch_popup .popup_inner .popup_header .close_popup {
  font-size: 20px;
  color: #FF5700;
  cursor: pointer;
  background: none;
  border: 1px solid #FF5700;
  border-radius: 50%;
  padding: 0 8px;
}

.mob_col_sch_popup .popup_inner h2 {
  font-size: 18px;
  font-weight: 800;
  line-height: 25.2px;
  letter-spacing: -0.36px;
  color: #222D2F;
  margin: 0;
}

.mob_col_sch_popup .popup_inner h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  color: #455356;
  margin: 0;
}

.mob_col_sch_popup .popup_inner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  color: #455356;
}

.mob-balance-header {
  border-bottom: 1px solid #E7EBEC;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.mob-balance-header h2 {
  font-size: 16px;
  font-weight: 700;
  color: #0C0C0C;
  margin: 0;
}

.mob-balance-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Space between boxes */
}

.mob-balance-detail-box {
  flex: 1 1 calc(50% - 10px);
  /* 2 columns with a gap */
  border: 1px solid #E7EBEC;
  border-radius: 10px;
  padding: 10px;
  background: #FFF;
  box-shadow: 0px 4px 8px rgba(153, 130, 118, 0.08);
}

.mob-balance-detail-header {
  font-size: 14px;
  font-weight: 600;
  color: #819093;
  margin-bottom: 8px;
}

.mob-balance-detail-data {
  font-size: 14px;
  font-weight: 700;
  color: #FF5700;
}

.mob-balance-detail-box p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media only screen and (max-width: 340px) {
.mob_ledger_table .page-table-box {
  height: calc(100vh - 450px) !important;
  }

}