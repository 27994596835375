.tabs-container{
    .tabs-header{
        border-bottom: 1px solid #E7EBEC;
        .tabs-header-item{
            cursor:pointer;
            padding: 12px 20px;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            border-radius: 20px 20px 0 0;
            border-bottom: 2px solid transparent;
            &.active{
                font-weight: 700;
                background-color: #FFF4ED;
                border-color: #FF5700;
            }
        }
    }
    .tabs-content{
        margin-top:24px;
        padding:4px;
    }
}