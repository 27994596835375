.owner-kyc{
    .box_contain{
        min-height: auto;
    }
    .sub_exit_box{
        flex-direction: row !important;
    }
    .sub_exit_data_txt h3{
        // margin-bottom: 0;

    }
    .amen-pad{
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
    }
}
.readOps{
    .box_contain{
        min-height: auto;
    }
}
.property-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #E7EBEC;
    padding-bottom: 24px;
    margin-bottom: 24px;

    .right-side-header{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.create-prop_modal{
    .title{
        color:  #222D2F;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 25.2px;
        letter-spacing: -0.36px;
    }
}

.create-prop_detail, .createBooking, .create-owner_kyc, .createOps{
    .form-label{
        color:  #617275;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.8px; /* 16.8px */
        letter-spacing: -0.12px;
    }   
    .form-control{
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #E7EBEC;
        background: #F2F5F5;
    }
    .modal-footer{
        margin-top: auto;
        padding-top: 24px;
        border-top: 1px solid #E7EBEC;

        gap: 16px;
        margin-top: 100px;
    }
    .title{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;
        letter-spacing: -0.16px;
    }
}
.text-right{
    text-align: right;
}
.createBooking{
    .title{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;
        letter-spacing: -0.16px;
    }
    .commen_table .add-bill{
        color: #FF5700;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 23.8px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        // text-align: right;

        img{
            margin-right: 8px;
        }
    }
    .trash-bg{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: rgba(222, 17, 53, 0.06);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .orange_brd_button{
        img{
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
    }
}

.create-owner_kyc{
    .title{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;
        letter-spacing: -0.16px;
    }
}
.TrailWrap{
    display: flex;
    flex-wrap: wrap;
    // padding-left: 0;

    >div{
        // flex: 0 0 33.333333%;
        flex: 0 0 31.6%;
        margin-right: 16px;
        margin-bottom: 20px;
    }
}
.TrailWrap-prop{
    display: flex;
    flex-wrap: wrap;
    // padding-left: 0;

    >div{
        // flex: 0 0 33.333333%;
        flex: 0 0 46%;
        margin-right: 16px;
        margin-bottom: 20px;
    }
}
.input-group-text{
    color: #FF5700;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
}
.form-select{
    border-radius: var(--corner-radius-input-field-input-corner-radius, 8px);
    border: 1px solid var(--neutrals-100, #E7EBEC);
    background: var(--neutrals-50, #F2F5F5);
    padding: 12px 16px;
}
.chip-orange{
    border-radius: 100px;
    background: var(--primary-50, #FFF4ED);
    display: flex;
    padding: 12px 20px;
    align-items: center;
    gap: 12px;
    width: auto;
    user-select: none;
    cursor: pointer;
}
.chip-active{
    border: 1px solid var(--primary-700, #C54300);
    background: var(--primary-base, #FF5700);
    color: var(--neutrals-white, var(--bw-color-white-900, #FFF));
    font-weight: 700;
}
.chips-wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    flex-wrap: wrap;
}
.createOps{
    .title{
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.12px;
    }
}
.flex-track{
    display: flex;
    flex: 0 0 100% !important;
    // margin-right: 16px;
    // margin-bottom: 20px;
}
.uti-div{
    display: flex;
    flex-wrap: wrap;
}
.uti-div > div {
    // width: 50%;
    flex: 0 0 46%;
    margin-right: 16px;
    margin-bottom: 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid var(--neutrals-100, #E7EBEC);

    .tab-pane {
        cursor: pointer;
        border-radius: 20px 20px 0px 0px;
        padding: 12px 20px;
        border-bottom: 2px solid transparent;
        font-size: 14px;
        line-height: 19.6px;
        color: #455356;
        font-weight: 500;

        &.active {
            background-color: #FFF4ED;
            border-color: #FF5700;
            color: #222D2F;
            font-weight: 700;
        }
    }
}

.EditButton{
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: var(--primary-100, #FFE3D5);
    border-color: var(--primary-100, #FFE3D5);
    color: var(--primary-base, #FF5700);

    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 170%; /* 23.8px */
    letter-spacing: 1.12px;
    text-transform: uppercase;
}