.managemenu-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .capsule {
        cursor: pointer;
        border: 1px solid #E7EBEC;
        padding: 12px 16px;
        border-radius: 32px;
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 500;
        color: #617275;
        background-color:#FFFFFF;
        &.active{
            background-color: #FFF4ED;
            border: 1px solid #FFD5C0;
            color: #FF5700;
        }
    }

    .cta-wastage{
        font-family: "Manrope";
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #FF5700;
        border: 1px solid #FFD5C0;
        background-color: white;
        padding: 12px 16px;
        border-radius: 32px;
        font-weight: 800;
    }
}