.submittedpoll-modal {
    div[class*="modal-header"] {
        padding: 24px;

        div[class*="modal-title"] {
            font-family: Zen Kaku Gothic Antique;
            font-size: 18px;
            font-weight: 800;
            line-height: 25px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #222D2F;
        }

        button[class*="btn-close"] {
            background-color: white;
            opacity: 1;
            color: #0C0C0C;
            width: 24px;
            height: 24px;
            padding: 8px;
            border-radius: 50%;
            box-shadow: 0 1px 8px #E7EBEC;
        }

    }

    div[class*="modal-body"] {
        .body-content {
            .menu-content {
                border: 1px solid #E7EBEC;
                border-radius: 20px;
                overflow: hidden;

                .sub-division {
                    padding: 20px;
                    border-bottom: 1px solid #E7EBEC;

                    .courses-section {
                        border-left: 1px solid #E7EBEC;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}