.revenue-bulk-upload {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .heading-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7ebec;

    .heading-left {
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .heading {
        font-size: 18px;
        line-height: 25.2px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .tab-pane {
      cursor: pointer;
      border-radius: 20px 20px 0px 0px;
      padding: 12px 20px;
      border-bottom: 2px solid transparent;
      font-size: 14px;
      line-height: 19.6px;
      color: #455356;
      font-weight: 500;

      &.active {
        background-color: #fff4ed;
        border-color: #ff5700;
        color: #222d2f;
        font-weight: 700;
      }
    }

    .content-view-header {
      h4 {
        margin-bottom: 0;
        margin-right: auto;
        color: #455356;
        font-weight: 700;
        font-size: 18px;
        line-height: 25.2px;
      }

      .btn-bulk {
        padding: 16px 20px;
        border-radius: 100px;
        font-size: 14px;
        line-height: 23.8px;
        font-weight: 800;
        border: 1px solid #ff9157;
        background: linear-gradient(90deg, #ff5700, #ff9057);
        color: #ffffff;
      }
    }

    table {
      border-radius: 20px;
      table-layout: fixed;
      width: 100%;
      tr {
        th,
        td {
          padding: 16px 12px;
        }
      }

      thead {
        tr {
          background-color: #f6faff;

          th {
            font-size: 12px;
            line-height: 16.8px;
            font-weight: 700;
            color: #455356;
          }
        }
      }

      tbody {
        tr {
          background-color: #ffffff;

          td {
            font-size: 14px;
            line-height: 19.6px;
            font-weight: normal;
            color: #455356;

            &:first-of-type {
              color: #0072da !important;
              font-weight: 500 !important;
            }

            .status {
              padding: 2px 8px;
              border-radius: 30px;
              font-size: 12px;
              font-weight: 500; 
              line-height: 16.8px;

              &.approved {
                color: #247d58;
                background-color: rgba(178, 243, 172, 0.5);
                border: none;
              }
              &.completed {
                color: #154230;
                background-color: rgba(121, 255, 108, 0.5);
                border: none;
              }

              &.initiated {
                color: #9a6600;
                background-color: rgba(255, 238, 219, 1);
                border: none;
              }

              &.rejected {
                color: #de3730;
                background-color: rgba(255, 237, 236, 1);
                border: none;
              }
              button.btn-approve,
              button.btn-reject {
                padding: 8px 16px;
                border-radius: 100px;
                font-size: 11px;
                line-height: 15.4px;
                font-weight: 800;
              }

              button.btn-approve {
                border: 1px solid #ff9157;
                background: linear-gradient(90deg, #ff5700, #ff9057);
                color: #ffffff;
              }

              button.btn-reject {
                border: 1px solid #ff9157;
                background: white;
                color: #ff5700;
              }
            }
          }
        }
      }
    }
  }
}
