.dialog-container {
  min-width: 500px;
  div[class*="modal-header"] {
    padding: 24px;

    div[class*="modal-title"] {
      font-family: Poppins;
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 900;
      color: #222d2f;
    }

    button[class*="btn-close"] {
      background-color: white;
      opacity: 1;
      color: #0c0c0c;
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 50%;
      box-shadow: 0 1px 8px #e7ebec;
    }
  }

  div[class*="modal-body"] {
    p.body-content {
      color: #455356;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      span {
        font-weight: 600;
        color: #222d2f;
      }
    }
  }

  div[class*="modal-footer"] {
    justify-content: center;
    gap: 16px;

    .btn-cancel {
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      background-color: white;
      color: #ff9157;
      font-weight: 800;
      border: 1.2px solid #ff9157;
    }

    .btn-submit {
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      font-weight: 800;
      background: linear-gradient(90deg, #ff5700, #ff9057);
      color: #ffffff;

      &.disabled {
        background: linear-gradient(90deg, #ff5700, #ff9057);
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }
}
