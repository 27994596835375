.input-select-container{
        label{
            font-size: 12px;
            line-height: 16.8px;
            font-weight: 500;
        }
        .input-select{
            border-radius: 4px;
            border: 1px solid #E7EBEC;
            background-color: #F7F9F9;
            div[class*="inputSelectY___control "]{
                cursor: pointer;
                min-height: 44.93px;
                border: 1px solid #E7EBEC !important;
                background-color: #F7F9F9;
                font-size: 14px;
                line-height: 19.6px;
                font-weight: 400;
                box-shadow: none !important;
                div[class*="inputSelectY___placeholder"]{
                    font-size: 14px;
                    line-height: 19.6px;
                    font-weight: 400;
                    color: #819093;
                }
                span[class*="inputSelectY___indicator-separator"]{
                    display: none;
                }
            }   
            div[class*="inputSelectY___menu-list"]{
                font-size: 14px;
                line-height: 19.6px;
                font-weight: 400;
                padding: 0px;
                div[class*="inputSelectY___option"]{
                    color: #222D2F;
                    font-weight: 400;
                    cursor: pointer;
                    &:hover,&[class*="inputSelectY___option--is-focused"]{
                        background-color: #FFD5C0;
                    }
                    &[class*="inputSelectY___option--is-selected"]{
                        background-color: #FF5700;
                        color: white;
                    }
                } 
                &[class*="inputSelectY___menu-list--is-multi"]{
                    max-height: 145px;
                }
            }        
        }
}