.action-menu-container{
    border:1px solid #E7EBEC;
    border-radius: 16px;
    .action-row{
        padding: 24px;
    }

    .menu-content {
        .view-container {
            border-top: 1px solid #E7EBEC;

            .view-cell-wrapper {
                .cell {
                    position: relative;
                    padding: 12px;
                    line-height: 16.8px;
                    font-size: 12px;
                    font-weight: 500;

                    &.heading {
                        color: #566366;
                        font-weight: 700;
                        background-color: #F6FAFF;
                    }

                    &.cell-content{
                        color: #819093;
                        font-weight: 500;
                        &:nth-of-type(1){
                            color:#455356;
                        }
                        &:nth-of-type(2){
                            background-color:#F7F9F9;
                        }
                    }

                    border-left:1px solid #E7EBEC;

                    &:first-of-type {
                        border-left: 0px solid #E7EBEC;
                    }

                    .arrow-left{
                        position: absolute;
                        cursor: pointer;
                        left:12px;
                    }
                    .arrow-right{
                        cursor: pointer;
                    }
                    .add-course{
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 800;
                        line-height: 20.4px;
                        color:#FF5700;
                    }
                }

                .first-cell {
                    min-width: 175px;
                }

                .equal-w-cell {
                    flex: 1 0 auto !important;
                }
            }

            &:last-of-type {
                border-bottom: 1px solid #E7EBEC;
            }

            &.heading-row-full {
                color: #455356;
                font-weight: 800;
                font-size: 14px;
                line-height: 23.8px;
                padding: 12px;

                &.breakfast {
                    background-color: #CFFBFC;
                }

                &.lunch {
                    background-color: #E5F3FF;
                }

                &.snacks {
                    background-color: #FFEEDB;
                }

                &.dinner {
                    background-color: #FFECF0;
                }
            }
        }
    }
}