.dialog-pdc-container {
  min-width: 800px;
  div[class*="modal-header"] {
    padding: 24px;

    div[class*="modal-title"] {
      font-family: Poppins;
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 900;
      color: #222d2f;
    }

    button[class*="btn-close"] {
      background-color: white;
      opacity: 1;
      color: #0c0c0c;
      width: 20px;
      height: 20px;
      padding: 8px;
      border-radius: 50%;
      box-shadow: 0 1px 8px #e7ebec;
    }
  }

  div[class*="modal-body"] {
    .column-5 {
      width: 20%;
      margin-bottom: 10px;
      .view-block {
        label {
          font-size: 14px;
          font-weight: 600;
          color: #819093;
          margin-bottom: 8px;
        }
        p {
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: 700;
          color: #455356;
        }
      }
    }
  }

  div[class*="modal-footer"] {
    justify-content: center;
    gap: 16px;
    padding-left: 24px;
    padding-right: 24px;
    .btn-cancel {
      flex: 1 1 auto;
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      background-color: white;
      color: #ff9157;
      font-weight: 800;
      border: 1.2px solid #ffe3d5;
    }

    .btn-submit {
      flex: 1 1 auto;
      padding: 12px 16px;
      border-radius: 100px;
      min-width: 218px;
      font-size: 14px;
      line-height: 23.8px;
      font-weight: 800;
      background: linear-gradient(90deg, #36b37e, #70d9ac);
      color: #ffffff;
    }
  }
}
