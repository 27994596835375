.saved-upcoming-container{
    padding: 12px 24px;
    border:1px solid #FFD5C0;
    background-color: #FFF4ED;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-title{
        font-weight: 700;
        font-size: 14px;
        line-height: 19.6px;
        color:#455356;
    }
    .menu-submmited-poll-view{
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 700;
        color:#FF5700;
        font-size: 14px;
        line-height: 19.6px;
        gap:12px;
    }
}
.menu-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
    color: #222D2F !important;
    margin-bottom: 0px;
}
button.draft{
    padding: 16px 24px;
    border-radius: 50px;
    border:1px solid #FFE3D5 !important;
    background: white;
    color: #FF5700 !important;
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
}
button.publish{
    padding: 16px 24px;
    border-radius: 50px;
    background: linear-gradient(90deg, #FF5700, #FF9057);
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
}
.next-poll{
    padding: 16px 32px;
    display: inline-flex;
    gap:8px;
    border-radius: 44px;
    background-color: white;
    border: 1px solid #FFD5C0 !important;
    color: #FF5700 !important;
    font-size: 14px;
    line-height: 23.8px;
    font-weight: 800;
}