.rdp-right-side-header {
    margin-bottom: 32px;
}

.main_tab .nav-tabs .nav-link {
    background: none;
    border-radius: 20px 20px 0px 0px;
    border: none;
    border-bottom: 2px solid transparent !important;
    padding: 12px 20px;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.035px;
    color: #455356;
}

.main_tab .nav-tabs .nav-link.active {
    border: none;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 2px solid #FF5700 !important;
    background: #FFF4ED;
    font-weight: 700;
}

.main_tab .nav-tabs .nav-link:focus,
.main_tab .nav-tabs .nav-link:hover,
.main_tab .nav-tabs .nav-link:hover:focus {
    border-radius: 20px 20px 0px 0px;
    border: none;
    border-bottom: 2px solid #FF5700 !important;
    background: #FFF4ED;
    font-weight: 700;
}

.main_tab .tab-content .active {
    background: none;
}

.brd_box_card {
    border: 1px solid #E7EBEC;
    box-shadow: 0px 15px 20px rgba(153, 130, 118, 0.08);
    border-radius: 20px;
    height: 100%;
    background: #fff;
}

.box_head {
    padding: 20px 20px 15px;
    border-bottom: 1px solid #E7EBEC;
}

.box_head h2 {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    color: #0C0C0C;
    margin: 0;
}

.box_head h2 span {
    font-weight: 400;
}

.box_head h2 img {
    position: relative;
    top: -2px
}

.box_contain {
    padding: 20px;
}

.box_card_data {
    padding-bottom: 20px;
    align-items: center;
}

.box_card_data h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #819093;
    margin-bottom: 0;
    min-width: 45%;
}

.box_card_data i {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #819093;
    margin-bottom: 0;
    font-style: normal;
}

.box_card_data span {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #455356;
    margin-bottom: 0;
    font-style: normal;
    min-width: 45%;
    text-align: right;
    word-wrap: break-word;
}

.box_card_data:last-child {
    padding-bottom: 0;
}

.box_contain {
    border-bottom: 1px solid #E7EBEC;
    min-height: 350px;
}

.box_footer {
    padding: 20px;
}

.box_footer .file-upload {
    position: relative;
}

.box_footer .file-upload__label {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.035px;
    color: #FF5700;
    text-align: right;
    margin: 0;
}

.box_footer .file-upload__label:hover {
    cursor: pointer;

}

.box_footer .file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.pt-32 {
    padding-top: 32px;
}

.table_main_tit h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.16px;
    color: #222D2F;
    margin: 0;
}

.download_icn_txt {
    font-size: 14px;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #FF5700 !important;
}

.pb-20 {
    padding-bottom: 20px;
}

.commen_table .page-table-box {
    border: 1px solid #E7EBEC;
    border-radius: 20px;
    height: auto;
}

.commen_table .page-table-box table th {
    background: #EBF7F7;
    border-bottom: 1px solid #E7EBEC;
    border-right: 1px solid #E7EBEC;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #455356;
    padding: 16px 20px;
    box-shadow: none;
}

.commen_table .page-table-box table th:first-child {
    border-radius: 20px 0 0 0;
}

.commen_table .page-table-box table th:last-child {
    border-radius: 0px 20px 0 0;
    border-right: none;
}

.commen_table .page-table-box table td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    color: #455356;
    padding: 16px 20px;
    border-bottom: 1px solid #E7EBEC;
    border-right: 1px solid #E7EBEC;
}

.commen_table .page-table-box table td:last-child {
    border-right: none;
}

.commen_table .page-table-box table td:first-child {
    font-weight: 600;
}

.commen_table .page-table-box table tr:hover td {
    box-shadow: none;
}

.orange_brd_button {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.035px;
    color: #FF5700;
    padding: 8px 16px;
    border: 1px solid #FF9157;
    border-radius: 100px;
    background: #fff;
}
.orange_brd_button img {
    position: relative;
    top: -1px;
  }

.commen_table td .bg_green_txt {
    padding: 2px 8px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    background-color: #E3F9E1;
    border-radius: 30px;
    color: #247D58;
    text-decoration: none;
}

.commen_table td .bg_warning_txt {
    padding: 2px 8px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    background-color: #FFEEDB;
    border-radius: 30px;
    color: #9A6600;
    text-decoration: none;
}

.small_box_data {
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #E7EBEC;
    margin-right: 16px;
    min-width: 170px;
}

.small_box_data h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #819093;
    margin-bottom: 8px;
}

.small_box_data span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #455356;
}

.p-16-20-button {
    padding: 16px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

.sub_exit_data_txt h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    margin-bottom: 8px;
    color: #819093;
}

.sub_exit_data_txt span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #455356;
    display: block;

}

.sub_exit_data_txt {
    margin-right: 40px;
    min-width: 15%;
}

.sub_exit_data_txt a {
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #FF5700 !important;
}

.custom_button{
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.8px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #FF5700 !important;
    cursor: pointer;
}

.sub_exit_box {
    border-bottom: 1px solid #E7EBEC;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.sub_exit_box h2 {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #0C0C0C;
    margin-bottom: 15px;
}

.sub_exit_box:last-child {
    border: none;
    margin: 0;
    padding: 0;
}

.exit_tab_box .box_contain {
    min-height: auto;
    border: none;
}

.sub_exit_box:last-child .sub_exit_data_txt span {
    margin-bottom: 0;
}

.exit_tab_box .brd_box_card {
    margin-bottom: 16px;
}

.exit_tab_box .brd_box_card:last-child {
    margin-bottom: 0px;
}

.alert_warring_box {
    border-radius: 8px;
    border: 1px solid rgba(255, 171, 0, 0.40);
    background: rgba(255, 171, 0, 0.10);
    padding: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    text-align: center;
    color: #DE9501;
}

.alert_success_box {
    border-radius: 8px;
    border: 1px solid rgba(54, 179, 126, 0.50);
    background: rgba(54, 179, 126, 0.10);
    padding: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    text-align: center;
    color: #36B37E;
}

.alert_error_box {
    border-radius: 8px;
    border: 1px solid rgba(222, 55, 48, 0.50);
    background: rgba(222, 55, 48, 0.10);
    padding: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    text-align: center;
    color: #DE1135;
}

.d-inline {
    display: inline-flex !important;
}

.gray_point {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 19.6px;
    color: #808080 !important;
    letter-spacing: 0.048px;
    padding: 2px 8px !important;
    border-radius: 30px;
    background: rgba(191, 191, 191, 0.50);
}

.green_point {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 19.6px !important;
    letter-spacing: 0.048px !important;
    color: #247D58 !important;
    border-radius: 30px !important;
    background: #E3F9E1 !important;
    padding: 2px 8px;
}

.brd-top {
    border-top: 1px solid #E7EBEC;
}

.p-12-16-button {
    padding: 12px 16px;
}

.head_part_date_modal h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    letter-spacing: -0.36px;
    margin-bottom: 8px;
    color: #000;
}

.head_part_date_modal p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #455356;
    margin-bottom: 24px;
}

.modal_input_main label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #617275;
}

.modal_input_main input:focus {
    border: none;
    outline: none;
}

.modal_input_main input {
    border-radius: 8px;
    border: 1px solid #E7EBEC;
    background: #F2F5F5;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #222D2F;
    padding: 12px 16px;
    width: 100%;
    height: auto !important;
}

.modal_input_main select {
    border-radius: 8px;
    border: 1px solid #E7EBEC !important;
    background: #F2F5F5 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #222D2F;
    padding: 12px 16px;
    width: 100%;
    height: auto !important;
}

.modal_input_main textarea {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #E7EBEC;
    background: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    resize: none;
    height: 65px !important;
}

.modal_btn_footer {
    display: flex;
    justify-content: flex-end;
}

.bg_gray_button {
    border-radius: 100px;
    background: #B8C3C5 !important;
    color: #fff;
}

.abscoding_modal .modal-body {
    padding: 30px 24px;
}

.confirm_noc_modal .modal-body {
    padding: 30px 24px 0;
}

.abscoding_modal .modal-content,
.confirm_noc_modal .modal-content {
    border-radius: 30px;
}

.close_modal {
    position: absolute;
    right: 23px;
    top: -16px;
    cursor: pointer;
}

.abscoding_modal .modal-dialog {
    max-width: 400px;
}

.confirm_noc_modal .modal-dialog {
    max-width: 1000px;
}

.modal_input_main .react-datepicker-wrapper {
    display: block;
}

.modal_input_main .react-datepicker,
.modal_input_main .react-datepicker__header {
    background: #FFF4ED;
    border: none;
    padding-bottom: 0;
    padding: 10px;
    border-radius: 12px;
}

.modal_input_main .react-datepicker__header {
    padding: 10px 0 0;
}

.modal_input_main .react-datepicker__day--selected,
.modal_input_main .react-datepicker__day:hover,
.modal_input_main .react-datepicker__day--keyboard-selected {
    border-radius: 100px;
    color: #fff;
    background: var(--gradient-orange, linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%));
}

.modal_input_main .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.modal_input_main .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border: none;
}

.modal_input_main .react-datepicker__navigation-icon::before {
    border-color: #222D2F !important;
}

.modal_input_main .react-datepicker__current-month {
    margin-top: -5px;
    margin-bottom: 10px;
}

.modal_input_main .react-datepicker__navigation {
    top: 11px;
}

img {
    max-width: 100%;
}

.modal_list_img img {
    border-radius: 14px;
}

.pos_rel {
    position: relative;
}

.black_shedow {
    position: relative;
    width: 100%;
    bottom: 0;
}

.black_shedow img {
    width: 100%;
}

.list_data_modal {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.list_data_modal h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: -0.18px;
    color: #fff;
    margin-bottom: 3px;
}

.list_data_modal p {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #fff;
    margin-bottom: 0;
}

.list_data_modal p span {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.16px;
    margin-left: 5px;
}

.modal_list_main {
    margin-bottom: 6px;
}

.modal_data_part .col-lg-4:nth-child(2n+1) {
    margin-bottom: 32px;
}

.list_label_box {
    background: #fff;
    padding: 11px 12px;
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    right: 17px;
    top: 0;
}

.list_label_box h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #617275;
    text-align: center;
    margin-bottom: 2px;

}

.list_label_box span {
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    letter-spacing: -0.36px;
    text-align: center;
    display: block;
}

.list_label_box span.green_clr {
    color: #36B37E;
}

.list_label_box span.orange_clr {
    color: #FFAB00;
}

.list_label_box span.red_clr {
    color: #EE5100;
}

.main_tab .tab-content .nav-tabs {
    border: none;
}

.main_tab .tab-content .nav-tabs .nav-link {
    border: 1px solid transparent !important;
    border-radius: 100px;
}

.main_tab .tab-content .nav-tabs .nav-link.active,
.main_tab .tab-content .nav-tabs .nav-link:focus,
.main_tab .tab-content .nav-tabs .nav-link:hover,
.main_tab .tab-content .nav-tabs .nav-link:hover:focus {
    border: 1px solid #FFD5C0 !important;
    background: #FFF4ED;
    border-radius: 100px;
    color: #FF5700;
}

.pd-24-20 {
    padding: 24px 20px;
}

.guest_img img {
    border-radius: 12px;
}

.guest_img {
    margin-right: 24px;
}

.guest_part h1 {
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    letter-spacing: -0.36px;
    width: 162px;
    margin-bottom: 12px;
}

.info_box {
    border-top: 1px solid #E7EBEC;
    padding-top: 20px;
    margin-top: 24px;
}

.info_box h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    color: #617275;
    margin-bottom: 0px;
}

.info_box span {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #222D2F;
}

.info_width {
    min-width: 20%;
    margin-right: 31px;
}

.box_contain.details_tab {
    min-height: auto;
    border: none;
}

.guest_part span {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #819093;
}

.guest_part b {
    color: #455356;
    margin-left: 5px;
}

.details_bg {
    position: absolute;
    bottom: 8px;
    right: 0;
    pointer-events: none;
}

.zindex9 {
    position: relative;
    z-index: 9;
}

span.pdf_clr {
    color: #0072DA !important;
    font-weight: 700 !important;
}

.exit_tab_box .brd_box_card {
    height: auto;
}

.red_not_verify {
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #DE1135 !important;
}

.red_not_verify img {
    position: relative;
    top: -2px;
}

.green_verify {
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #36B37E !important;
}

.green_verify img {
    position: relative;
    top: -2px;
}

.kyc_sub {
    border-bottom: 1px solid #E7EBEC;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.kyc_sub:last-child {
    border: none;
    margin: 0;
    padding: 0;
}

.kyc_sub h2 {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.12px;
    color: #0C0C0C;
    margin-bottom: 20px;
}

.pdf_clr img {
    position: relative;
    top: -2px
}

.agreement_main .box_contain {
    padding: 0;
}

.img_round_icon_box {
    margin-right: 32px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.img_round_icon_box img {
    max-width: 100%;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: top;
}

.w460 {
    width: 460px;
    margin: auto;
}

.check_img {
    text-align: center;
    margin-bottom: 24px;
}

.small_box_center {
    border-radius: 20px;
    width: 230px;
    margin: auto;
    background: #FFF4ED;
    padding: 20px;
    margin-bottom: 24px;
}

.small_box_center h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.16px;
    color: #222D2F;
    margin-bottom: 8px;
}

.small_box_center h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    letter-spacing: -0.36px;
    color: #0C0C0C;
    text-align: center;
    margin-bottom: 0;
}

.disc_text {
    color: #222D2F;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: -0.18px;
}

.p-18-32-button {
    padding: 18px 32px !important;
}

.modal-content {
    border-radius: 30px !important;
}

.modal-body {
    padding: 30px 24px !important;
}

.check_popup .modal-dialog {
    max-width: 400px;
}

.exit_tab_box .info_box {
    margin: 0;
    padding: 0;
    border: none;
}

.check_text h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.16px;
    color: #455356;
}

.check_text span {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    color: #617275;
}

.check_text .info_img img {
    padding-right: 12px !important;
}

.check_text .info_width {
    min-width: auto;
    margin-right: 45px;
}

.red_round_check {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background: #FFEDEA;
    padding: 2px 10px;
    margin-left: 10px;
}

.red_round_check span {
    width: 8px;
    height: 8px;
    background: #DE3730;
    margin-right: 6px;
    border-radius: 100%;
}

.red_round_check p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    margin-bottom: 0;
    color: #DE3730;
}

.orange_round_check {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background: #FFEEDB;
    padding: 2px 10px;
    margin-left: 10px;
}

.orange_round_check span {
    width: 8px;
    height: 8px;
    background: #DB9404;
    margin-right: 6px;
    border-radius: 100%;
}

.orange_round_check p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    margin-bottom: 0;
    color: #DB9404;
}

.green_round_check {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background: #E3F9E1;
    padding: 2px 10px;
    margin-left: 10px;
}

.green_round_check span {
    width: 8px;
    height: 8px;
    background: #008A2E;
    margin-right: 6px;
    border-radius: 100%;
}

.green_round_check p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.048px;
    margin-bottom: 0;
    color: #008A2E;
}

.mr-3 {
    margin-right: 16px;
}

.pr-2 {
    padding-right: 8px;
}

.pr-1 {
    padding-right: 5px;
}

.fancybox__container {
    z-index: 9999 !important;
}

.agreement_main.exit_tab_box {
    background: #fff;
    z-index: 9;
    position: relative;
}

.agreement_badge {
    padding: 8px 0px 8px 0px;
    text-align: center;
    border-radius: 30px;
    background: rgba(227, 249, 225, 0.50);
    color: #247D58 !important;
    font-family: Manrope;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16.8px !important;
    letter-spacing: -0.12px !important;
}

.edit_button{
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: var(--primary-100, #FFE3D5);

    color: var(--primary-base, #FF5700);

/* Text M - 14/Extrabold */
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 170%; /* 23.8px */
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .commen_table .page-table-box table th {
        padding: 5px 10px;
        font-size: 13px;
        text-align: center;
    }
    .commen_table .page-table-box table td {
        padding: 10px 2px;
    }
    .orange_brd_button {
       padding: 2px 2px;
       font-size: 12px;
      }
}